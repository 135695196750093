import React from 'react';

const NotFoundPage = () => {
  return (
    <>
      <h1>Looks like something went wrong here...</h1>
    </>
  );
};

export default NotFoundPage;
